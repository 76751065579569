import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
import { SEO } from 'src/containers/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Privacy Policy" description="We provide an overview about what happens with your personal data when you talk to Carbon Bot." mdxType="SEO" />
    <Section mdxType="Section">
      <h4 {...{
        "id": "1-a-brief-overview-of-data-protection"
      }}>{`1`}{`.`}{` A brief overview of data protection`}</h4>
      <p><strong parentName="p">{`General indications`}</strong></p>
      <p>{`We provide you with a simple overview about what happens with the conversations you have with Carbon Bot.`}</p>
      <p><strong parentName="p">{`What is the purpose of collecting Carbon Bot conversations?`}</strong></p>
      <p>{`Carbon Bot is part of a research project, aiming to create a database of conversations used to train
conversational AI assistants. The intention is to make this database public for researchers anywhere to use.`}</p>
      <p><strong parentName="p">{`What data do we collect?`}</strong></p>
      <p>{`We collect the messages sent by users and by the bot in conversations, together with the timestamps of those messages.
Each conversation is tied to a user ID provided by facebook.`}</p>
      <p>{`We `}<strong parentName="p">{`do not collect any personal data`}</strong>{` from you as a user of Carbon Bot.
As owners of the Carbon Bot Facebook page, we can see the usernames of people who have spoken to the bot.
However we do not store this information anywhere. We also never collect your email or any details from your facebook profile.`}</p>
      <p><strong parentName="p">{`What data will be made public?`}</strong></p>
      <p>{`We will make public the conversation transcripts (so the messages exchanged between the user and the bot).
We will not include the message timestamps or any user IDs.
Users are warned at the start of the conversation not to share any personal details.
Before releasing this dataset we will try to remove personal data that was shared accidentally on a "best effort" basis.`}</p>
      <h4 {...{
        "id": "2-details-and-rights"
      }}>{`2`}{`.`}{` Details and Rights`}</h4>
      <p>{`The processing of the chatbot data is based on Art. 6 (1) (f) GDPR. The data is used for research purposes and made publicly available to help researchers in the conversational AI field. Users can freely decide if they want to use the chatbot and therefore help the underlying purpose.`}</p>
      <p><strong parentName="p">{`Responsible body`}</strong></p>
      <p>{`The responsible body for data processing is:`}</p>
      <p>{`Rasa Technologies GmbH, Schoenhauser Allee 175, 10119 Berlin`}</p>
      <p>{`Phone: +49 (0)30 9154 5443`}</p>
      <p>{`E-Mail: `}<a parentName="p" {...{
          "href": "mailto:hi@rasa.com"
        }}>{`hi@rasa.com`}</a></p>
      <p>{`The responsible body is the juridical agent that decides on the aims and means of use of your personal data (ie names, Email).`}</p>
      <p><strong parentName="p">{`Revoking consent to data processing`}</strong></p>
      <p>{`Many data processing operations are only allowed with your explicit consent. If you have given consent but wish to revoke that consent, this can be done simply by emailing us. Until revocation, it is legal for us to process your data.`}</p>
      <p><strong parentName="p">{`Right to appeal to the supervising authority`}</strong></p>
      <p>{`You have the right to appeal with the supervising authority in the case of a privacy policy violation. The supervising authority for data protection matters is the state data protection officer in the corresponding federal state in which our company is located.`}</p>
      <p>{`You may find the data protection officers and their contacts at the following `}<a parentName="p" {...{
          "href": "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        }}>{`link`}</a>{`.`}</p>
      <p><strong parentName="p">{`Right of data transfer`}</strong></p>
      <p>{`You have the right to request, in any format, the data that we automatically process with your consent.`}</p>
      <p>{`If you wish us to transfer your data to another responsible person, this can be done assuming it is within our technical ability.`}</p>
      <p><strong parentName="p">{`Information, Blocking and Deletion`}</strong></p>
      <p>{`Within the current legal regulations, you have the right to freely request information regarding your saved personal data, including its origin, recipient and processing. If applicable, you also have the right to the rectification, blocking, or deletion of those data. For that as well as further inquiries about personal data, you may reach us through our contact information.`}</p>
      <h4 {...{
        "id": "3-data-protection-officer"
      }}>{`3`}{`.`}{` Data protection officer`}</h4>
      <p><strong parentName="p">{`Legally recommended data protection officer`}</strong></p>
      <p>{`We have ordered the following data protection officer for our company`}</p>
      <p>{`Frank Trautwein | `}<a parentName="p" {...{
          "href": "https://freshcompliance.de/en/"
        }}>{`Fresh Compliance`}</a></p>
      <p>{`E-Mail: `}<a parentName="p" {...{
          "href": "mailto:dsb@freshcompliance.de"
        }}>{`dsb@freshcompliance.de`}</a></p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      